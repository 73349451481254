import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
export const configJSON = require("./config");

const phoneRegex = /^(\d\s?){10}$/,
  numericRegex = /^\d+(\.\d+)?$/,
  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const defaultValues: any = {
  isLoading: false,
  userToken: "",
  isAlert: false,
  alertMsg: "",
  alertType: "success"
};

export interface ValidationFieldsType {
  field: string;
  errorKey: string;
  errorMessage: string;
  regex?: RegExp;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface FooterResponse {
  data: FooterData;
  errors: any[];
  message: string;
  error?: { address: string[] };
}

export interface FooterData {
  address: string;
  full_phone_number: string;
  email: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  address: string;
  mobile: string;
  email: string;
  addressError: string;
  mobileError: string;
  emailError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFooterListApiCallId: string = "";
  submitFooterApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      ...defaultValues,
      address: "",
      mobile: "",
      email: "",
      addressError: "",
      mobileError: "",
      emailError: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getFooterData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getFooterListApiCallId:
          this.handleGetFooterResponse(responseJson);
          break;
        case this.submitFooterApiCallId:
          this.handleSubmitFooterResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleGetFooterResponse(resJson: FooterResponse) {
    if (
      resJson.errors &&
      resJson.errors.length > 0 &&
      Object.keys(resJson.errors[0])[0] === "token"
    ) {
      this.navigationLoginScreen();
    } else {
      this.setState({
        email: resJson.data.email,
        address: resJson.data.address,
        mobile: resJson.data.full_phone_number.slice(2),
        isLoading: false
      });
    }
  }

  getFooterData() {
    this.setState({ isLoading: true });
    this.getFooterListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getFooterApiEndpoint,
      token: this.state.userToken
    });
  }

  checkFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "address",
        errorKey: "addressError",
        errorMessage: "Address is Required"
      },
      {
        field: "mobile",
        errorKey: "mobileError",
        errorMessage: "Phone number is Required",
        regex: phoneRegex
      },
      {
        field: "email",
        errorKey: "emailError",
        errorMessage: "Email is Required",
        regex: emailRegex
      }
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  handleSubmitFooter() {
    const { address, email, mobile } = this.state;
    const formValues = {
      address,
      email,
      mobile
    };
    if (!this.checkFormValidations(formValues)) {
      this.setState({ isLoading: true });
      this.submitFooterApiCallId = apiCall({
        method: configJSON.apiUpdateUserType,
        endPoint: configJSON.updateFooterApiEndpoint,
        token: this.state.userToken,
        contentType: configJSON.validationApiContentType,
        body: {
          data: {
            full_phone_number: "91" + mobile,
            address: address,
            email: email
          }
        }
      });
    }
  }

  handleSubmitFooterResponse(response: FooterResponse) {
    if (response.error && response.error.address) {
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: "Address " + response.error.address[0]
      });
    } else if (
      response.errors &&
      [configJSON.tokenExpiredMessage, configJSON.tokenInvalidMessage].includes(
        response.errors[0].token
      )
    ) {
      this.navigationLoginScreen();
    } else if (response.message && response.data) {
      this.setState({
        alertMsg: response.message,
        alertType: "success",
        isAlert: true,
        isLoading: false
      });
    }
  }

  handleInputChange({ target }: { target: { value: string; name: string } }) {
    let { name, value } = target;

    let error = "";
    if (name === "mobile") {
      const testPassed = value !== "" && !phoneRegex.test(value),
        numericTest = value !== "" && !numericRegex.test(value);
      error = testPassed ? "Please enter a valid phone number" : "";
      value = numericTest ? this.state.mobile : value;
    }
    if (name === "email") {
      error = !emailRegex.test(value)
        ? "Please enter a valid email address"
        : "";
    }

    this.setState({
      [name]: value,
      [`${name}Error`]: error
    } as any);
  }
  // Customizable Area End
}
// Customizable Area Start
export const apiCall = (data: APIPayload) => {
  let { contentType, method, endPoint, body, type, token } = data;
  let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  const header = {
    ...(contentType ? { "Content-Type": contentType } : {}),
    token
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !type ? JSON.stringify(body) : body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const validationsLoop = (
  validationFields: ValidationFieldsType[],
  updatedValues: any,
  setState: (value: (prevState: any) => any) => void
) => {
  let hasError = false;
  validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
    if (
      !updatedValues[field] ||
      (updatedValues[field] && updatedValues[field].length === 0) ||
      (regex && !regex.test(updatedValues[field]))
    ) {
      setState(prevState => ({
        ...prevState,
        [errorKey]: errorMessage
      }));
      hasError = true;
    } else {
      setState(prevState => ({
        ...prevState,
        [errorKey]: ""
      }));
    }
  });
  return hasError;
};
// Customizable Area End
